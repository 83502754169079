import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {FormService} from 'src/app/shared/service/form.service';
import {ModalService} from 'src/app/shared/service/modal.service';
import {CheckboxOpts} from 'src/app/shared/model/checkbox-opts.model';

@Component({
  selector: 'app-institutional-agreement',
  templateUrl: './institutional-agreement.component.html',
  styleUrls: ['./institutional-agreement.component.scss']
})
export class InstitutionalAgreementComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Input() isAgent = false;
  studentModalContent =
    `<div>
  <h2>Institutional Agreement</h2>
    <p>The mission of Liberty University is to develop Christ-centered men and women with the values, knowledge, and skills essential to impact the world. We expect that our students understand and respect our mission and purpose while enrolled at Liberty University.</p>
    <h4>By submitting my application, I understand and agree that:</h4>
    <ol>
      <li>Liberty University can make investigations in connection with my application. Information obtained by Liberty University from my application and any investigations will be kept private, subject to Liberty’s <a href="https://www.liberty.edu/offices/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.  I may, upon request, review information considered by the admissions committee in making its decision.</li>
      <li>I will comply with all applicable Liberty University policies and procedures, including those policies and procedures related to financial aid, tuition and fees, payment plans, refunds, adjustments, withdrawals, and other policies contained in the applicable Academic Catalog.</li>
      <li>Liberty University’s Student Honor Code, also known as “The Liberty Way”, outlines the university’s expectations for its students and provides guidelines for living in a Christian educational community, rooted in biblical principles and supported by Liberty University’s <a href="https://www.liberty.edu/residential/what-we-believe/" target="_blank">doctrinal statement</a>. The Liberty Way prohibits certain academic and non-academic misconduct and provides disciplinary procedures and possible sanctions for addressing misconduct. If I accept an offer of admission to Liberty University and enroll, I agree that I will comply with the terms of the <a href="https://libertyedu.public.na2.doctract.com/doctract/documentportal?c=08DC705E85FD97868A21574157D773E8" target="_blank">Liberty University Student Honor Code</a>.</li>
      <li>All information in my application is complete and accurate to the best of my knowledge. Liberty University is authorized to make whatever inquiries as necessary to certify the accuracy of my records. I understand that withholding information or giving false information may make me ineligible for admission or result in my dismissal from Liberty University. Moreover, withholding information or giving false information on my application may result in loss of financial aid, which may also result in a balance due to Liberty University for which I will be responsible; selection for Financial Aid Verification; and monetary fines and/or imprisonment.</li>
      <li>Liberty University may disclose information from my education records to appropriate government agencies for the purposes of assisting such agencies in the administration of financial aid programs, for addressing matters of public health and safety, and for other purposes authorized by the Family Educational Rights and Privacy Act (FERPA).</li>
      <li>A non-refundable, non-transferable $50 Application Fee will be applied to the current application upon enrollment.</li>
      <li>As a condition of applying to or continuing in certain Liberty University programs, and as a condition of being placed in certain internships, externships or practicums for academic credit, I may be required to complete an up-to-date background check. I will be personally responsible for all fees associated with any such background check. Background check fees are non-refundable.</li>
      <li>Essential functions of this program include computer competency and use of word processing, spreadsheets, database, internet research, media presentations, and email communication, as well as the technology requirements found at www.liberty.edu/techrequirements. If I cannot complete these essential functions, Liberty University recommends registering for its computer courses to gain these skills. Liberty University's Help Desk is able to assist you with ensuring that your computer meets the necessary hardware requirements.
        <ol>
          <li>To request a reasonable accommodation to assist with meeting these essential functions and skills, or other course requirements, please contact Liberty University's Office of Disability Academic Support (ODAS).</li>
          <li>Liberty University's Coordinator of LU Online Disability Academic Support can be reached by telephone at (434) 592-5417 or by email at luoodas@liberty.edu.</li>
          <li>Liberty University's Director of Disability Academic Support (Residential) can be reached by telephone at (434) 582-2159 or by email at odas@liberty.edu.</li>
          <li>Liberty University complies with all applicable federal and state laws and regulations.</li>
        </ol>
      </li>
    </ol>
    <p>Liberty University does not engage in unlawful discrimination or harassment because of race, color, ancestry, religion, age, sex, national origin, pregnancy or childbirth, disability or military veteran status in its educational programs and activities. Liberty University maintains its Christian mission and reserves its right to discriminate on the basis of religion to the extent that applicable law respects its right to act in furtherance of its religious objectives. The following persons have been designated to coordinate Liberty University’s compliance with certain anti-discrimination laws: Coordinator of LU Online Disability Academic Support at (434) 592-5417 or luoodas@liberty.edu; Director of Disability Academic Support (Residential) at (434) 582-2159 or odas@liberty.edu; Executive Director of Title IX at (434) 592-4999 or TitleIX@liberty.edu.</p>
    <p>By submitting my application, I agree that I have carefully read and understand this agreement, and I agree to all the terms, representations, and statements above.</p>
  </div>`;

  agentModalContent =
    `<div>
  <h2 class="text-center">Abbreviated Institutional Agreement</h2>
    <p class="text-center">Liberty University’s mission is to develop Christ-centered men and women with the values, knowledge, and skills essential to impact the world. We expect our students to understand and respect our mission. Our Student Honor Code provides guidelines for our Christian educational community, rooted in Liberty’s Doctrinal Statement. It prohibits certain academic and non-academic misconduct and provides disciplinary procedures.</p>
    <p class="text-center">By submitting your application through this call, you agree that all information you provided is complete and accurate, and that Liberty University can make inquiries in connection with your application to make an admissions decision. You also agree that, if you enroll, you will comply with all applicable policies and procedures, including the Academic Catalog and Student Honor Code.</p>
    <p class="text-center">Liberty University does not engage in unlawful discrimination or harassment because of race, color, ancestry, religion, age, sex, national origin, pregnancy or childbirth, disability or military veteran status in its educational programs and activities. Liberty University maintains its Christian mission and reserves its right to discriminate on the basis of religion to the extent that applicable law respects its right to act in furtherance of its religious objectives. The names and contact information of those who coordinate Liberty’s compliance with anti-discrimination laws will be forwarded to you along with links to the Academic Catalog, Doctrinal Statement, and Student Honor Code.</p>
    <p class="text-center">Be aware that a non-refundable, non-transferable $50 Application Fee will be applied to your application when you enroll.</p>
    <p class="text-center">Do you understand these statements I have read and agree to be bound by those terms and conditions?</p>
  </div>`;


  iaCheckboxOpts: CheckboxOpts[] = [
    {
      'name': 'institutional_agreement_check',
      'required': true,
      'disabled': false,
      'defaultVal': false,
      'label': '<span class=\'ialabel\'><b>I have read, understand, and agree</b> to the terms and conditions of the <span class=\'link toggle-ia\'>Institutional Agreement</span>.</span>',
      'errorMessage': 'Indicate that you agree to the Institutional Agreement terms and conditions'
    }
  ];

  constructor(
    public formService: FormService,
    public modalService: ModalService
  ) {
  }

  ngOnInit(): void {

    this.formGroup.addControl('institutional_agreement', new FormGroup({}));
  }

  ngOnDestroy() {
    this.formGroup.removeControl('institutional_agreement');
  }

  elementClicked(element: HTMLElement) {
    if (element.classList.contains('toggle-ia')) {
      this.triggerModal();
    }
  }

  triggerModal() {
    this.modalService.displayButton = true;
    const modalContent = this.isAgent ? this.agentModalContent : this.studentModalContent;

    this.modalService.displayModal(modalContent, 'Agree & Close', () => { // serves as the close modal callback
      if (this.getYesCheckControl().value === false) {
        this.getYesCheckControl().setValue(true);
      }
    });
  }

  getYesCheckControl(): AbstractControl {
    return this.formGroup.get('institutional_agreement').get('institutional_agreement_check');
  }

}

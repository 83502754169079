import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { EProgLevel } from 'src/app/shared/model/prog-level.model';
import { first, takeUntil } from 'rxjs/operators';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { CookieService } from 'src/app/shared/service/cookie.service';
import { ECampusCode, FormService } from 'src/app/shared/service/form.service';
import { EFormType } from 'src/app/shared/model/app-form-builder.model';
import { DomService } from 'src/app/shared/service/dom.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { PopupService } from 'src/app/shared/service/popup.service';
import { RouterService } from 'src/app/shared/service/router.service';
import { SchoolService } from 'src/app/shared/service/school.service';
import { StudentService } from 'src/app/shared/service/student.service';
import { FormComponent } from '../form.component';
import { BlockedApplicationService } from '../../shared/service/blocked-application.service';
import { NationService } from 'src/app/shared/service/nation.service';
import { StateService } from 'src/app/shared/service/state.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { InitService } from 'src/app/shared/service/init.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { IncompleteAppService } from 'src/app/shared/service/incomplete-app.service';
import { IncompleteApp } from 'src/app/shared/model/applyLUApiObjects/incomplete-app.model';
import { Student } from 'src/app/shared/model/student.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { OtpService } from "../../shared/service/otp.service";

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styles: [' .header-text {font-size: 18px !important;}']
})

export class StudentFormComponent extends FormComponent implements OnInit, OnDestroy, AfterViewInit {

  displayGimmeCaptcha = environment.envType !== 'prod' && environment.envType !== 'pprd';
  showYourDegreeErrors = false;
  showPreviousEducationErrors = false;
  showPersonalInformationErrors = false;
  showAgreeAndSubmitErrors = false;

  studentProgramsLoadedSub = new Subscription();
  endSubscriptions = new Subject<void>();

  loggedIn = false;
  loggingIn = false;

  resumeText = 'Resuming your previous application ...';

  testCaptchaToken = '';
  testCaptchaResponse: any = null;

  constructor(
    public cdr: ChangeDetectorRef,
    public navigationService: NavigationService,
    public programService: ProgramService,
    public applicationService: ApplicationService,
    public routerService: RouterService,
    public studentService: StudentService,
    public route: ActivatedRoute,
    public formService: FormService,
    public schoolService: SchoolService,
    public appIdService: AppIdService,
    public cookieService: CookieService,
    public popupService: PopupService,
    public nationService: NationService,
    public stateService: StateService,
    private router: Router,
    public el: ElementRef,
    public domService: DomService,
    private blockedAppService: BlockedApplicationService,
    public modalService: ModalService,
    public initService: InitService,
    private authenticationService: AuthenticationService,
    private incompleteAppService: IncompleteAppService,
    private recaptchaV3Service: ReCaptchaV3Service,
    //private otpService: OtpService
  ) {
    super(
      cdr,
      navigationService,
      programService,
      applicationService,
      route,
      formService,
      schoolService,
      appIdService,
      popupService,
      routerService,
      cookieService,
      nationService,
      stateService,
      studentService,
      el,
      domService,
      initService,
      modalService
    );

    const theUrl = this.router.url;

    if (theUrl.indexOf('/spc') > -1) {
      this.applicationService.spcApp = true;
    } else if (theUrl.indexOf('/festivals') > -1) {
      this.applicationService.festivalsApp = true;
    }

    // build our reactive App Form structure
    this.applicationForm = this.appFormBuilder.buildForm(EFormType.student);

  }

  ngOnInit(): void {

    super.ngOnInit();

    this.navigationService.getEmitActiveSectionSub().pipe(takeUntil(this.endSubscriptions)).subscribe(newSection => {
      const prevSectionName = this.activeSectionName;
      const nextSectionName = newSection.name;
      this.maybeActivateSection(prevSectionName, nextSectionName);
      // added to send update request to save section status' to database
      this.applicationForm.updateValueAndValidity();
    });

    this.navigationService.setupSectionValidation(true, this.applicationForm, this.endSubscriptions);

    this.formService.prefillForm.subscribe(prefillObj => {
      this.prefilling = true;

      setTimeout(() => {

        const email = prefillObj.formData['personalInformation']['email'];
        if (email && email !== '') {
          this.updateEmailAddress(email);
          this.blockedAppService.checkBlockedEmail(email);
        }
        this.activeSectionName = prefillObj.activeSectionName;
        if (!this.loggingIn) {
          this.prefilling = false;
        }

        if (this.activeSectionName !== 'yourDegree') {
          // this is a safe way of using document as body is read-only HTMLElement
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }


      }, 500);
    });

    this.formService.getStudentDataImportedSub().pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.applicationForm.get('data_fields').get('studentHasLoggedIn').setValue(true);
    });


  } // end ngOnInit

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.applicationService.spcApp || this.applicationService.festivalsApp) {

      let autoProgramCode = '';
      let autoWappCode = '';
      // Prepare a few properties depending on special apps
      if (this.applicationService.spcApp) {
        autoProgramCode = 'SPCU-BS-R';
        autoWappCode = 'RU';
      } else if (this.applicationService.festivalsApp) {
        autoProgramCode = 'UNDE-BS-R';
        autoWappCode = 'RU';
      }

      // Once programs are ready, auto-select program
      this.studentProgramsLoadedSub = this.programService.getAllProgramsLoadedSub().subscribe(() => {

        this.applicationForm.get('yourDegree').get('program').setValue(autoProgramCode);
        this.applicationForm.get('yourDegree').get('wappCode').setValue(autoWappCode);

        const autoProgram = this.programService.getProgramByProgramCode(autoProgramCode);
        autoProgram.wappCode = autoWappCode;
        if (autoProgram) {
          // assumes that all campus/levels are Res Bachelor, if other programs are ever added these values need to be gathered dynamically
          this.formService.updatedCampus.next(ECampusCode.R);
          this.formService.updatedLevel.next(EProgLevel.Bachelor);
          this.programService.programSubNext(autoProgram);
        }
      });
      this.navigationService.possibleSections[1].locked = true;
      this.navigationService.possibleSections[1].visited = false;
      this.navigationService.possibleSections[2].locked = true;
      this.navigationService.possibleSections[2].visited = false;
    }


    this.loggedIn = this.authenticationService.isLoggedIn();
    if (
      (
        (this.loggedIn && this.studentAlreadyHasLoggedIn(false))
        || this.studentAlreadyHasLoggedIn(true)
      )
      && !this.applicationService.spcApp && !this.applicationService.festivalsApp
    ) {
      this.checkForAutoResume();
    } else { // no split test or login, regular resume processes
      this.runResumeProcess();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
    this.studentProgramsLoadedSub.unsubscribe();
  }

  runResumeProcess() {
    this.resumeText = 'Resuming your previous application ...';
    // check to see if we should trigger resume based on cookies or generate a new app
    if (this.route.snapshot.queryParams.p_a && this.route.snapshot.queryParams.p_a !== '') {
      this.modalService.triggerWelcomeBackModal.next(true);
    } else if (this.routerService.getResumeLinkParams() != null && this.routerService.getResumeLinkParams().appID !== 'Unavailable' && !this.applicationService.festivalsApp) {
      this.appIdService.resumeLink = true;
      this.cookieService.addCookie(this.cookieService.cookieEnum.APP_ID, this.routerService.getResumeLinkParams().appID, 30);
      this.cookieService.addCookie(this.cookieService.cookieEnum.RESUME_KEY, this.routerService.getResumeLinkParams().resumeKey, 30);
      this.modalService.triggerResumeModal.next(this.applicationForm);
    } else if (
      this.cookieService.checkCookie(this.cookieService.cookieEnum.APP_ID)
      && this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID) !== 'Unavailable'
      && this.cookieService.checkCookie(this.cookieService.cookieEnum.RESUME_KEY)
      && !this.applicationService.festivalsApp
    ) {
      this.appIdService.resumeLink = false;
      this.modalService.triggerResumeModal.next(this.applicationForm);
    } else {
      this.closeResumeOverlay();
      this.appIdService.resumeLink = false;
      this.appIdService.load().subscribe();
    }
    this.cdr.detectChanges();
  }

  maybeScrollToCampus() {
    // when this scroll functionality is set back as default on the decorative card, can delete this function and just put the scrollToControl into the click event handler in the template
    if (this.cookieService.isVwoTest('548', '2')) {
      this.scrollToControl('campus-anchor');
    }
  }

  async maybeActivateSection(currentSectionName: string, nextSectionName: string) {
    this.navigationService.validateSection(currentSectionName);

    const nextSection = this.navigationService.possibleSections.find(section => section.name === nextSectionName);

    // let student activate desired section if already visited, else will only navigate if desired section is valid
    if (
      (nextSection && nextSection.visited) ||
      (nextSection.visited && this.navigationService.sectionIsValid(currentSectionName))
    ) {
      if (nextSectionName === 'agreeSubmit') {
        if ((this.applicationService.isBlockedOnlinePhone || this.applicationService.isBlockedOnlineEmail) && this.formService.currentCampus === 'D') {
          this.modalService.displayModal('You currently have a hold on your account that prevents your application from proceeding further. It must be resolved to submit your application for consideration. <br><br>Please contact LUO Admissions at  <a href="tel:800-424-9595">  (800) 424-9595</a> for further information and assistance.', 'Okay', () => {
          });
        } else if ((this.applicationService.isBlockedResPhone || this.applicationService.isBlockedResEmail) && this.formService.currentCampus === 'R') {
          this.modalService.displayModal('<br>You currently have a hold on your account that prevents your application from proceeding further. It must be resolved to submit your application for consideration.<br><br>Please contact Resident Admissions at <a href="tel:800-543-5317">(800) 543-5317</a> (Undergraduate programs) or <a href="tel:877-298-9617">(877) 298-9617</a> (Graduate programs) for further information and assistance.<br>', 'Okay', () => {
          });
        } else {
          this.activeSectionName = nextSectionName;
        }
      } else {
        this.activeSectionName = nextSectionName;
      }
    } else {
      // show error messages
      // mark all fields as touched
      this.applicationForm.get(currentSectionName).markAllAsTouched();
      this.applicationForm.get(currentSectionName).updateValueAndValidity();
      // scroll to the top
      if (currentSectionName === 'yourDegree') {
        this.showYourDegreeErrors = true;
      } else if (currentSectionName === 'previousEducation') {
        this.showPreviousEducationErrors = true;
      } else if (currentSectionName === 'personalInformation') {
        this.showPersonalInformationErrors = true;
      } else if (currentSectionName === 'agreeSubmit') {
        this.showAgreeAndSubmitErrors = true;
      }

      this.cdr.detectChanges();

      const errorContainer = this.el.nativeElement.querySelector('.error-container');

      if (errorContainer) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });


        setTimeout(() => { // let the scrollTo finish, otherwise focus() forces the page to jump suddenly
          if (errorContainer) {
            errorContainer.focus();
          }
        }, 300);
      }
    }
  }

  studentAlreadyHasLoggedIn(hasLoggedIn = true) {
    return this.applicationForm.get('data_fields').get('studentHasLoggedIn').value === hasLoggedIn;
  }

  checkForAutoResume() {
    // if the student has logged in due to split test, we know that their cookies will be set
    // instead of prompting resume modal, just trigger resume process and auto resume
    // then, after resume prefill is done, make student check for LUID and prefill with returned data
    const appIdCookie = this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID);
    const resumeKeyCookie = this.cookieService.getCookie(this.cookieService.cookieEnum.RESUME_KEY);

    this.resumeText = 'We\'re retrieving your information, this might take a few seconds.';
    this.prefilling = true;
    this.loggingIn = true;

    // no app to resume, need to gather a new one first
    if (!appIdCookie || !resumeKeyCookie) {

      this.appIdService.load().subscribe(() => {
        this.autoResumeAndGetStudentData();
      });
    } else {
      this.autoResumeAndGetStudentData();
    }
  }

  /** only works if user has both appID and resume cookies */
  async autoResumeAndGetStudentData() {

    const appIdCookie = this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID);
    const resumeKeyCookie = this.cookieService.getCookie(this.cookieService.cookieEnum.RESUME_KEY);

    if (appIdCookie && resumeKeyCookie && appIdCookie !== 'null' && resumeKeyCookie !== 'null') {

      this.incompleteAppService.getIncompleteAppByResumeKey(appIdCookie, resumeKeyCookie).subscribe({

        next: async (incompleteApp: IncompleteApp) => {

          if (incompleteApp.id === null || incompleteApp.id === 0) {
            // we had cookies to resume with, but the incomplete app came back null
            // Regular users shouldn't experience this, but we'll start up a new app
            this.closeResumeOverlay();
            this.appIdService.load().subscribe();
            return false;
          }

          this.appIdService.setProperties(incompleteApp);

          // === resume app data === //
          this.applicationService.resumeApp(incompleteApp);

          // === resume form data === //
          if (incompleteApp.formObject) {
            const formData = JSON.parse(incompleteApp.formObject);

            this.formService.prefillForm.next({ formData: formData, activeSectionName: 'yourDegree' });

            if (this.studentAlreadyHasLoggedIn(false)) {
              this.prefilling = true; // keep prefilling turned on until student search is done
            }

          }

          // check to see if they've already logged in once
          if (this.studentAlreadyHasLoggedIn(false)) {

            this.prefilling = true; // keep prefilling turned on until student search is done
            // === gather student data from Banner now that they are logged in === //
            this.studentService.studentLuidSearch().subscribe({

              next: studentData => {

                if (!studentData.pidm) {
                  this.closeResumeOverlay();
                  return false;
                }

                // sometimes getting 9 digit zip, only desiring 5 digit
                const studentZip = studentData.zip;
                if (studentZip?.length > 5) {
                  studentData.zip = studentZip.substring(0, 5);
                }

                const studentObj = new Student();
                Object.assign(studentObj, studentData);

                // once they review and confirm the data, the resume modal will emit their data
                this.modalService.triggerStudentResumeModal.next(studentObj);
                this.closeResumeOverlay();

              },

              error: err => {
                this.closeResumeOverlay();
              }
            }); // end gather student data

          } else {

            this.closeResumeOverlay();
          }

        }, // end getIncompleteAppByResumeKey sub

        error: (e) => {
          this.closeResumeOverlay();
        }

      })


    } else {

      this.runResumeProcess();
      this.closeResumeOverlay();
    }

  }

  getCaptchaToken() {
    this.recaptchaV3Service.execute('gimmeCaptcha').subscribe((token: string) => {
      this.testCaptchaToken = token;
    });
  }

  submitEvent() {
    this.recaptchaV3Service.execute('submitEvent').subscribe({
      // Handle success: reCAPTCHA verification was successful, go ahead with submission
      next: async (token: string) => {
        this.onSubmit(token);
      },
      error: (error) => {
        // Handle failure: reCAPTCHA verification failed
        console.error('reCAPTCHA verification failed:', error);
      }
    });
  }

  async onSubmit(token?: string): Promise<void> {

    this.validateForm();

    if (this.formErrors.length > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    } else {
      // success
      const theProgram = this.programService.getProgramByProgramCode(this.applicationService?.application?.majorFoses[0]?.programCode);

      // Now requiring online students to provide OTP code validation
      /*if (theProgram.campCode == 'D') {

        // build otp options
        // email will always be an option
        const email = this.applicationForm.get('personalInformation').get('email').value.replace(/\s/g, '');

        const otpOptions: { phone: { label: string, value: string }, email: { label: string, value: string } } = {
          // email: { label: email, value: email }, re enable email later
          email: {label: "", value: ""},
          phone: { label: "", value: "" }
        };

        const phoneInput = this.applicationForm.get('personalInformation').get('phone_fields').get('phoneNumberInput').value.trim();
        const phoneArea = this.applicationForm.get('personalInformation').get('phone_fields').get('completePhone').get('area').value.replace(/\s/g, '');
        const phoneNumber = this.applicationForm.get('personalInformation').get('phone_fields').get('completePhone').get('phoneNumber').value.replace(/\s/g, '');

        let cleanPhone = phoneArea + phoneNumber;
        if (!cleanPhone.includes('+')) {
          cleanPhone = '+1' + cleanPhone
        }

        otpOptions.phone = { label: phoneInput, value: cleanPhone }

        this.modalService.triggerOtpModal.next(otpOptions);

        // set up subscription to listen for code validation
        this.otpService.validatedOTPToken.pipe(first()).subscribe(async otpType => {

          // the modal will emit an empty string if it is cancelled and closed out of
          // this way the subscription is closed up until they hit the submit button and trigger the modal again
          if (otpType !== "") {
            await this.applicationService.submitApplication(this.applicationForm.getRawValue(), token);
            this.routerService.navigate('/thank-you/.', theProgram, true);
          }

        })

      } else*/ { // Res student, submit like normal
        await this.applicationService.submitApplication(this.applicationForm.getRawValue(), token);
        this.routerService.navigate('/thank-you/.', theProgram, true);
      }
    }
  }

  closeResumeOverlay() {
    this.prefilling = false;
    this.loggingIn = false;
  }
}

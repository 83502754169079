import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FileUploadComponent} from 'src/app/form/input/generic/file-upload/file-upload.component';
import {EmailMessage, EmailType} from 'src/app/shared/model/email-message.model';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {EmailService} from 'src/app/shared/service/email.service';

@Component({
  selector: 'app-essay-upload',
  templateUrl: './essay-upload.component.html',
  styleUrls: ['./essay-upload.component.scss']
})
export class EssayUploadComponent implements OnInit {
  @Input()
  replyToAddress = '';

  @Input()
  fullName: string;

  @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;

  toAddress = EmailMessage.ADMISSION_DOCS_RES;
  approvedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
  approvedFileTypesNames = [' .docx', ' .doc'];

  sentToAPI = false;
  failedSendToAPI = false;
  fileListLength = 0;

  constructor(
    private emailService: EmailService,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
  }

  sendEssays(fileList: File[]) {
    const webId = this.applicationService.webId;
    this.fileListLength = fileList.length;

    this.fileUploadComponent.showLoadingAnimation = true;
    const messageBody = 'Essay: ' + this.fullName + ((webId && webId.length > 0) ? ', ' + webId : '') + '\nEmail: ' + this.replyToAddress.toUpperCase();
    const messageSubject = 'Essay: Residential Applicant';

    const emailMessage: EmailMessage = new EmailMessage(EmailMessage.NO_REPLY, this.toAddress,
      this.replyToAddress, messageSubject, messageBody, EmailType.ESSAY);

    this.emailService.sendEmailWithAttachment(fileList, emailMessage).subscribe(() => {
      this.fileUploadComponent.showLoadingAnimation = false;
      if (this.emailService.successfulUpload) {
        this.sentToAPI = true;
      } else {
        this.failedSendToAPI = true;
      }
    }, () => {
      this.fileUploadComponent.showLoadingAnimation = false;
      this.failedSendToAPI = true;
    }
    );
  }



}
